import { render, staticRenderFns } from "./adminCrmFeedback.vue?vue&type=template&id=5069a64f&scoped=true&"
import script from "./adminCrmFeedback.vue?vue&type=script&lang=js&"
export * from "./adminCrmFeedback.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5069a64f",
  null
  
)

export default component.exports